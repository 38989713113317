import React from 'react'
import AdComponent from '../AdComponent/AdComponent'

function AboutSection() {
  return (
    <>
      <div>
        <p className='custom-heading h3'>About Multi Game Zone</p>
        <div>
          <p className='custom-heading text-start fw-normal'>Welcome to Multi Game Zone! We're thrilled to have you on board as we strive to build the world's premier browser-based gaming platform. Our mission is simple: to ensure that players enjoy a seamless gaming experience and to provide robust support for developers of all kinds.</p>
          <p className='custom-heading text-start fw-normal'>Whether you're at home, school, or on the go, our games offer endless entertainment across desktops, tablets, and mobile phones. Multi Game Zone has become the go-to destination for millions around the world when they want to play online, making it the ultimate gaming playground. Best of all, it's free and open to everyone.</p>
          {<AdComponent
            client="ca-pub-3550658214779566"
            slot="1413804079"
            format="auto"
            style={{ display: 'block', width: '100%', height: '280px' }}
          />}
          {/* <p className='custom-heading text-start fw-normal'>Play your favorite games instantly in your web browser and start enjoying them from anywhere today.</p> */}
          <p className='custom-heading text-start fw-normal'>Join us to experience unlimited gaming across all your devices, from PCs and laptops to Chromebooks, the latest Apple gadgets, and Android devices.</p>
        </div>
      </div>
    </>
  )
}

export default AboutSection
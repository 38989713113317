import React from 'react';
import './Footer.css';
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <div className='footer'>
      <div className='d-flex justify-content-between'>
        <a href="https://www.multigamezone.com/privacy-policy">Privacy-policy</a>
        <div className='d-flex'>
          <a href="https://www.facebook.com/share/Y1a4uGYSHegy1Fhd/?mibextid=qi2Omg" target='_blank' style={{ color: '#0866ff' }}><FaFacebookSquare size={20} /></a>
          <a href="https://www.instagram.com/multi_gamezone?igsh=MWNzZHYzNWMwbHN4dw==" target='_blank' style={{ color: '#e41beb' }}><FaInstagram className='ms-3' size={20} /></a>
        </div>
        <a href="https://www.multigamezone.com/">multigamezone.com</a>
      </div>
    </div>
  )
}

export default Footer